<template>
  <div class="home">
    <div class="step">
      <div class="wa">
        <a
          href="https://api.whatsapp.com/send?phone=525561444479"
          target="_blank"
        >
          <span style="color: white">¡Contáctanos!</span>
          <img src="img/wa.png" width="65px"/>
        </a>
      </div>
      <Header></Header>
    </div>
    <div class="step3">
      <!-- <CarouselPartners></CarouselPartners> -->
      <!-- <Servicios></Servicios> -->
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
// import CarouselPartners from "../components/CarouselPartners.vue";
import Footer from "../components/Footer.vue";
// import Servicios from "../components/Servicios.vue";

export default {
  name: "Home",
  components: {
    Header,
    // CarouselPartners,
    Footer,
    // Servicios,
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  position: relative;
}
.home {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
}

.step3 {
  z-index: 3;
}
.wa {
  width: auto;
  position: fixed;
  right: 3%;
  bottom: 5%;
  z-index: 8;
}

.wa img {
  width: 80px;
}

.wa a {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wa a span {
  background-color: #2aa71a;
  color: black;
  padding: 5px 10px;
  margin-right: 5px;
  font-weight: 600;
  border-radius: 5px;
}
</style>
