<template>
  <section class="header-image">
    <img src="img/logo-color.png" alt="art-tatoo" />
  </section>
  <!--<nav>
    <div class="nav_content">
      <a href="#">Ver galeria</a>
      <a href="#">¿Quiénes somos?</a>
      <a href="#">Agenda tú cita</a>
    </div>
  </nav>-->
  <section class="main">
    <div class="swiper-content">
      <swiper
        :slides-per-view="1"
        :autoplay="{ delay: 3000 }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="item in dataCarousel" :key="item.id">
          <picture class="img-banner">
            <source media="(max-width: 640px)" :srcset="item.imagenMovil" />
            <img :src="item.imagen" :alt="item.descripcion" />
          </picture>
          <div class="slide-content"></div>
        </swiper-slide>
        <!-- <swiper-slide :key="1">
          <picture class="img-banner">
            <source media="(max-width: 500px)" srcset="img/carrusel/_02.jpg" />
            <img src="img/carrusel/_01.jpg" alt="" />
          </picture>
          <div class="slide-content"></div>
        </swiper-slide> -->
        <!-- <swiper-slide :key="2">
          <img src="img/carrusel/_02.jpg" class="img-banner" />
          <div class="slide-content"></div>
        </swiper-slide>
        <swiper-slide :key="3">
          <img src="img/carrusel/_03.jpg" class="img-banner" />
          <div class="slide-content"></div>
        </swiper-slide>
        <swiper-slide :key="4">
          <img src="img/carrusel/_04.jpg" class="img-banner" />
          <div class="slide-content"></div>
        </swiper-slide>
        <swiper-slide :key="5">
          <img src="img/carrusel/_05.jpg" class="img-banner" />
          <div class="slide-content"></div>
        </swiper-slide> -->
      </swiper>
    </div>

    <div class="wrap-box py-5">
      <div class="row px-0 mx-0 wrap-box-content">
        <div class="card-form col-md-6 col-12">
          <form autocomplete="off">
            <h2 class="mb-4 title-register">Regístrate</h2>

            <div class="content-card-form">
              <template v-if="!continuar">
                <div class="form-group text-left">
                  <label for="name">Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    autocomplete="off"
                    v-model="formInit.nombre"
                    required
                  />
                </div>

                <div class="form-group text-left">
                  <label for="apellidos">Apellidos</label>
                  <input
                    type="text"
                    class="form-control"
                    id="apellidos"
                    v-model="formInit.apellidos"
                    required
                  />
                </div>

                <div class="form-group text-left">
                  <label for="name">Teléfono</label>
                  <input
                    type="tel"
                    class="form-control"
                    id="name"
                    v-model="formInit.telefono"
                    required
                    minlength="10"
                    maxlength="10"
                  />
                </div>
                <div class="form-group text-left">
                  <label for="name">Correo</label>
                  <input
                    type="email"
                    class="form-control"
                    id="name"
                    autocomplete="off"
                    v-model="formInit.correo"
                    required
                  />
                </div>
              </template>

              <template v-if="continuar">
                <div class="form-group text-left">
                  <label for="fecha">Fecha de visita</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="formInit.fechaCita"
                    v-bind:min="fechaMinima"
                    name="fecha"
                    @change="validateDays($event)"
                  />
                </div>
                <div class="form-group text-left">
                  <label for="hora">Hora de visita</label>
                  <input
                    type="time"
                    class="form-control"
                    v-model="formInit.horaCita"
                    name="hora"
                    min="11:00"
                    max="20:00"
                    @blur="validateHours($event)"
                  />
                </div>

                <div class="form-group text-left">
                  <label for="tipoTatuaje">Tipo de tatuaje</label>
                  <select
                    class="form-control"
                    name="tipoTatuaje"
                    v-model="formInit.idTipoTatuaje"
                  >
                    <option value="">Seleccionar</option>
                    <option v-for="o in tipoTatuajes" :key="o.id" :value="o.id">
                      {{ o.descripcion }}
                    </option>
                  </select>
                </div>

                <div class="form-group text-left">
                  <label for="areaCuerpo">Area del cuerpo</label>
                  <select
                    class="form-control"
                    name="areaCuerpo"
                    v-model="formInit.idAreaCuerpo"
                  >
                    <option value="">Seleccionar</option>
                    <option v-for="o in areaCuerpo" :key="o.id" :value="o.id">
                      {{ o.descripcion }}
                    </option>
                  </select>
                </div>

                <div class="form-group text-left">
                  <label for="tamano">Tamaño del tatuaje</label>
                  <select
                    class="form-control"
                    name="tamano"
                    v-model="formInit.idTamanoTatuaje"
                  >
                    <option value="">Seleccionar</option>
                    <option
                      v-for="o in tamanoTatuajes"
                      :key="o.id"
                      :value="o.id"
                    >
                      {{ o.cantidad }}
                    </option>
                  </select>
                </div>

                <div class="form-group text-left">
                  <input
                    style="display: none"
                    @change="uploadFiles($event)"
                    type="file"
                    accept="image/*"
                    ref="archivos"
                    multiple
                  />
                  <button class="btn-upload" @click="open()" type="button">
                    <img src="img/logo-upload.svg" width="25" /><span
                      style="margin-left: 5px"
                      >Subir archivos</span
                    >
                  </button>
                </div>
                <div
                  class="form-group text-left"
                  v-if="uploadedImages.length > 0"
                >
                  <div class="dd-flex flex-row">
                    <img
                      :src="image"
                      width="60"
                      style="margin-right: 10px"
                      v-for="image in uploadedImages"
                      :key="image"
                    />
                  </div>
                </div>
              </template>
              <button
                type="button"
                class="btn-submit bg-blue"
                v-if="!continuar"
                v-on:click="continuar = true"
              >
                Continuar
              </button>
              <button
                type="button"
                class="btn-submit bg-orange"
                v-if="continuar"
                v-on:click="continuar = false"
              >
                Regresar
              </button>
              <button
                type="submit"
                class="btn-submit bg-green btn-register"
                v-if="continuar"
                v-on:click="enviar($event)"
              >
                <span>Registrarse</span>
                <div id="loading" v-if="loading"></div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ApiSneakers from "@/api/api-sneakers";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
import "swiper/swiper.scss";
import Swal from "sweetalert2";

const fechaHoy = new Date();

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade]);

export default {
  name: "Header",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      idxCarrusel: null,
      tipoTatuajes: [],
      tamanoTatuajes: [],
      areaCuerpo: [],
      dataCarousel: [],
      dataCarouselAll: [],
      uploadedImages: [],
      continuar: false,
      idxDataCarousel: 0,
      selectedDataCarousel: null,
      iconos: null,
      btnRegistro: "Registrarse",
      fechaMinima: `${fechaHoy.getFullYear()}-${fechaHoy.getMonth() + 1}-${
        fechaHoy.getDate() < 10 ? "0" + fechaHoy.getDate() : fechaHoy.getDate()
      }`,
      loading: false,
      formInit: {
        idOrigenCita: 2,
        nombre: null,
        apellidos: null,
        telefono: null,
        correo: null,
        fechaCita: null,
        horaCita: null,
        idTipoTatuaje: null,
        idAreaCuerpo: null,
        idTamanoTatuaje: null,
        images: [],
      },
      imgCarrusel: [{ id: 1, img: "img/logo-color.png" }],
    };
  },
  methods: {
    async getCats() {
      this.tipoTatuajes = (await ApiSneakers.get("/tipo-tatuajes")).data;
      this.tamanoTatuajes = (await ApiSneakers.get("/tamano-tatuajes")).data;
      this.areaCuerpo = (await ApiSneakers.get("/area-cuerpo")).data;
    },
    async getCarouselBanners() {
      const content = (await ApiSneakers.get("/banners")).data;
      if (content?.length > 0) {
        this.dataCarousel = content;
      }
    },
    onSwiper() {
      // console.log(swiper);
    },
    onSlideChange(e) {
      const idx = e.realIndex;
      if (this.formInit.idTipoSeguro === null) {
        this.selectedDataCarousel = this.dataCarousel[idx];
        this.idxDataCarousel = idx;
      }
    },
    async enviar(e) {
      e.preventDefault();
      try {
        let images = {};
        if (this.formInit.images.length > 0) {
          images = this.formInit.images.reduce((acc, value, idx) => {
            acc = {
              ...acc,
              [`image-${idx}`]: value,
            };
            return acc;
          }, {});
        }
        const fecha = `${this.formInit.fechaCita} ${this.formInit.horaCita}:00`;
        this.loading = true;
        this.btnRegistro = "Cargando...";
        const request = {
          idOrigenCita: 2,
          nombre: this.formInit.nombre,
          apellidos: this.formInit.apellidos,
          telefono: this.formInit.telefono,
          correo: this.formInit.correo,
          fechaCita: fecha,
          idTipoTatuaje: this.formInit.idTipoTatuaje,
          idAreaCuerpo: this.formInit.idAreaCuerpo,
          idTamanoTatuaje: this.formInit.idTamanoTatuaje,
          archivosTotales: this.formInit.images.length,
          ...images,
        };

        let formData = new FormData();
        for (let key in request) {
          formData.append(key, request[key]);
        }
        await ApiSneakers.post("/agendar-cita", formData);
        this.loading = false;
        this.btnRegistro = "Registrarse";
        this.showAlert(true);
        this.resetForm();
      } catch (e) {
        // console.log(e);
        this.showAlert(false);
        this.loading = false;
        this.btnRegistro = "Registrarse";
      }
    },
    resetForm() {
      this.continuar = false;
      this.uploadedImages = [];
      this.formInit = {
        idOrigenCita: 2,
        nombre: null,
        apellidos: null,
        telefono: null,
        correo: null,
        fechaCita: null,
        horaCita: null,
        idTipoTatuaje: null,
        idAreaCuerpo: null,
        idTamanoTatuaje: null,
        images: [],
      };
    },
    showAlert(success) {
      console.log(success);
      const title = success ? "Registro exitoso" : "Error al registrarse";
      const action = success ? "success" : "error";
      Swal.fire(title, "", action);
    },
    open() {
      this.$refs.archivos.click();
    },
    uploadFiles(e) {
      this.uploadedImages = [];
      this.formInit.images = [];
      const fileList = e.target.files;
      fileList.forEach((file) => {
        this.formInit.images.push(file);
        const src = URL.createObjectURL(file);
        this.uploadedImages.push(src);
      });
    },
    validateDays(e) {
      const day = new Date(e.target.value).getUTCDay();
      if (day === 2)
        Swal.fire("No es posible agendar cita en martes", "", "info").then(
          () => {
            this.formInit.fechaCita = null;
          }
        );
    },
    validateHours(e) {
      if (!e.target.validity.valid)
        Swal.fire('No es posible agendar la cita a esta hora', "", "info").then(() => this.formInit.horaCita = null);
    },
  },
  mounted() {
    void this.getCats();
    void this.getCarouselBanners();
  },
  watch: {
    selectedInsurance(value) {
      if (!value) return (this.dataCarousel = this.dataCarouselAll);
      this.dataCarousel = this.dataCarouselAll.filter(
        (x) => x.idTipoSeguro === value
      );
      this.selectedDataCarousel = this.dataCarousel[0];
    },
  },
};
</script>

<style scoped lang="scss">
$orange: #000;
$green: #ff00ff;
$blue: #29abe2;

nav {
  width: 100%;
  background-color: $orange;
  color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  .nav_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      padding: 8px 20px;
      text-decoration: none;
      color: $blue;
      font-weight: 600;
      font-size: 16px;
      &:hover {
        color: $green;
      }
    }
  }
}

.img-banner img {
  object-fit: contain;
  // height: 100%;
  width: 100%;

  @media screen and (max-width: 335px) {
    height: 100%;
  }
}

.btn-upload {
  background-color: $blue;
  color: #fff;
  font-weight: 600;
  padding: 7px 15px;
  border: none;
  border-radius: 5px;
}

.header-image {
  height: 120px;
  padding: 0px 0;
  background-color: #000;

  img {
    height: 100%;
  }
}

.btn-register {
  display: flex;
  justify-content: center;
  align-items: center;
}

#loading {
  margin-left: 10px;
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 3px solid rgba(255, 255, 255, 0.836);
  border-radius: 50%;
  border-top-color: $orange;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.bg-blue {
  background-color: $blue;
}

.bg-orange {
  background-color: $orange;
}

.bg-green {
  background-color: $green;
}

.title-register {
  font-weight: 900;
  padding: 15px 0;
  text-align: center;
  background-color: $orange;
  color: #fff;
  font-size: 22px;
}

.main {
  // height: 80vh;
  position: relative;
  height: 100%;

  .swiper-content {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .wrap-box {
    position: relative !important;
    width: 100%;
    height: 100%;
    // top: 0;
    // left: 0;
    z-index: 2;

    h1 {
      font-weight: 600;
    }

    .wrap-box-content {
      @media screen and (max-width: 769px) {
        flex-direction: column-reverse;
      }
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  min-height: 100%;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-size: cover;
}

.card-form {
  width: 80%;
  margin: auto;
  min-width: 300px;
  max-width: 460px;
  min-height: 400px;
  background-color: #fff;
  border-radius: 15px;
  padding: 0;
  overflow: hidden;
  border: none;

  // position: absolute;
  // top: 50%;
  // left: 75%;
  // transform: translate(-50%, -50%);
  // z-index: 9;
  box-shadow: 0px 0px 10px rgb(0, 0, 0);

  .content-card-form {
    padding: 20px 30px;
  }

  label {
    margin-bottom: 0px;
    font-weight: 600;
  }

  // @media screen and (max-width: 800px) {
  //   left: 50%;
  // }

  // @media screen and (max-width: 420px) {
  //   width: 100%;
  //   min-width: 100%;
  // }

  .btn-submit {
    width: 100%;
    color: white;
    text-align: center;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    padding: 8px 0;
    margin-top: 24px;
  }
}
</style>
