<template>
  <footer>
    <!-- <div class="bg-ondas w-100">
      <div class="container pt-5"></div>
    </div> -->

    <div class="container pt-4">
      <section class="footer-web row col-12">
        <div class="item col-md-3 col-sm-12">
          <div class="content">
            <img src="img/logo-gris.png" width="100" alt="logo" />
          </div>
        </div>

        <div class="item col-md-3 col-sm-12 middle-item">
          <div class="content">
            <h4>Ubicación</h4>
            <a href="https://goo.gl/maps/qLaQ9yh8J3JpP8VD6" target="_blank">
              <p>Génova 71 Interior 202, Juárez, Cuauhtémoc, 06600, CDMX</p>
            </a>
          </div>
        </div>

        <div class="item col-md-3 col-sm-12 middle-item">
          <div class="content">
            <h4>Contáctanos</h4>
            <a href="tel:+5561444479">
              <p>55 6144 4479</p>
            </a>
            <!--<a href="">
              <p>abdielgarcia@segurocontigo.com</p>
            </a>-->
          </div>
        </div>

        <div class="item col-md-3 col-sm-12">
          <div class="content">
            <h4>Síguenos</h4>
            <a
              href="https://www.facebook.com/ArtTattoo0709/"
              class="mr-3"
              target="_blank"
            >
              <img src="img/fb.svg" width="30" height="30" alt="facebook" />
            </a>
            <a href="https://www.instagram.com/artattoocdmx/" target="_blank">
              <img src="img/ig.svg" width="30" height="30" alt="instagram"/>
            </a>
          </div>
        </div>
      </section>
    </div>

    <section class="container">
      <div class="logo-mit text-center py-4"></div>
    </section>

    <div class="container py-4 terminos-condiciones">
      <span>Copyright © 2021 Art Tattoo</span>
      <span>&nbsp; Aviso de privacidad</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
$primary: #111;
$orange: #000;
$green: #ff00ff;
$blue: #29abe2;

footer {
  background-color: $blue;
  color: #000;
}

.bg-ondas {
  background-image: url("/assets/images/footer-ondas.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.footer-web {
  // max-width: 700px;
  margin: auto;
  display: flex;
  flex-direction: row;

  .item {
    text-align: start;
  }

  .item .content {
    width: fit-content;
    margin: auto;

    h4 {
      font-weight: 800;
      font-size: 18px;
    }
    p {
      color: #000;
      margin-bottom: 6px;
    }
  }
}

.logo-mit {
  border-bottom: 1px solid #000;
  img {
    width: 100px;
  }
}

.terminos-condiciones {
  display: flex;
  flex-direction: row;
  justify-content: center;

  span {
    color: #000;
  }
}

@media screen and (max-width: 767px) {
  .footer-web .item .content {
    width: auto;
  }
  .middle-item {
    margin: 10px 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .footer-web {
    flex-direction: column;
  }
  .right button {
    margin-top: 15px;
  }
}
</style>
